import { mutations } from './types'

export default {
  [mutations.SET_REPORTS]: (state, reports) => {
    state.reports = reports
  },
  [mutations.SET_IS_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },
  [mutations.SET_IS_NEXT_REPORTS_LOADING]: (state, isLoading) => {
    state.isNextReportsLoading = isLoading
  },
  [mutations.SET_FETCH_NEXT]: (state, fetchNext) => {
    state.fetchNext = fetchNext
  }
}
