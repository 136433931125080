import { carfaxReportStates, CARFAX_PAGE_LIMIT } from './constants'
import { actions, mutations } from './types'

import { sdk } from 'Services/shelfNetworkSdk'

export default {
  async [actions.LOAD_REPORTS] ({ commit }, vin = '') {
    commit(mutations.SET_IS_LOADING, true)

    const query = {
      'filter[state]': carfaxReportStates.fulfilled,
      'filter[vin]': vin,
      'page[limit]': CARFAX_PAGE_LIMIT,
      include: 'daily_stats'
    }

    const response = await sdk.carfax.getReportsPage(query)
    commit(mutations.SET_REPORTS, response.data)

    commit(mutations.SET_FETCH_NEXT, response.fetchNext)
    commit(mutations.SET_IS_LOADING, false)
  },

  async [actions.LOAD_MORE_REPORTS] ({ commit, state }) {
    commit(mutations.SET_IS_NEXT_REPORTS_LOADING, true)

    const response = await state.fetchNext()
    commit(mutations.SET_REPORTS, state.reports.concat(response.data))

    commit(mutations.SET_FETCH_NEXT, response.fetchNext)
    commit(mutations.SET_IS_NEXT_REPORTS_LOADING, false)
  }
}
