export function genCarfaxState () {
  return {
    reports: [],
    isLoading: false,
    isNextReportsLoading: false,
    fetchNext: () => {}
  }
}

export default genCarfaxState
